.main-nav {
	display: flex;

	&__item:not(:last-child) {
		margin-right: 2rem;
	}

	&__link {
		position: relative;
		display: block;
		padding: 2rem 0;

		font-size: 1.8rem;
		font-weight: 500;
		color: #32498A;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			right: 50%;
			bottom: 0;
			width: 0;
			height: 4px;
			background-color: $base-color;
			transform: translateX(-50%);
			transition: all 0.2s ease-out;
		}

		&.is-active,
		&:hover {

			&::before {
				width: 100%;
			}
		}
	}
}

@media (max-width: $tablet-width) {

	.main-nav {

		&__item:not(:last-child) {
			margin-right: 1rem;
		}

		&__link {
			padding: 1.5rem 0;
			font-size: 1.6rem;
		}
	}
}