.input-field {
	width: 100%;
	height: 30px;
	padding: 5px 0;
	border-bottom: 1px solid $base-text;

	font-size: 1.6rem;
}

@media (max-width: $tablet-width) {

	.input-field {
		font-size: 1.2rem;
	}
}