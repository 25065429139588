.button-contacts {
	display: none;
	margin-top: 2rem;
	margin-bottom: -2rem;
}

@media (max-width: $mobile-width) {

	.button-contacts {
		display: flex;
		justify-content: space-between;
	}
}