.social-list {
	display: flex;

	&__item {
		width: 32px;
		height: 32px;
		margin-right: 1.5rem;

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		display: block;
		color: $white-text;

		transition: all 0.2s ease-out;

		&:hover {
			color: $white-hover;
		}
	}

	&--small & {

		&__item {
			width: 20px;
			height: 20px;
			margin-right: 1rem;
		}
	}
}

@media (max-width: $tablet-width) {

	.social-list {

		&__item {
			margin-right: 1rem;
		}
	}
}