.news-list {
	display: flex;
	margin: 0 -1.5rem;
	margin-top: -3rem;

	&__item {
		margin: 0 1.5rem;
		margin-top: 3rem;
	}

	&__image {
		position: relative;
		display: block;
		width: auto;
		height: auto;
		margin-bottom: 2.5rem;
		border-radius: 20px;

		transition: all 0.25s ease-out;

		&:hover::before,
		&:focus::before {
			content: '';
			position: absolute;
			display: block; 
			width: 100%;
			height: 100%;
			box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
		}

		img {
			border-radius: inherit;
			object-fit: cover;
		}
	}

	&__title {
		display: inline-block;
		margin-bottom: 1.5rem;
		font-size: 2.2rem;
		font-weight: 500;
		color: $base-color;

		transition: all 0.25s ease-out;

		&:hover,
		&:focus {
			opacity: 0.7;
			text-decoration: underline;
		}
	}

	&__description {
		font-size: 1.8rem;
	}
}

@media (max-width: $tablet-width) {

	.news-list {

		&__image {
			margin-bottom: 1.5rem;
		}

		&__title {
			margin-bottom: 0.5rem;

			font-size: 1.6rem;
		}

		&__description {
			font-size: 1.2rem;
		}
	}
}

@media (max-width: $mobile-width) {

	.news-list {

		&__image {

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__description {
			display: none;
		}
	}
}