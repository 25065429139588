.partners-list {
	display: flex;

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100% / 8);

		img {
			width: 90%;
			height: 90%;
		}
	}
}