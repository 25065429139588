.contact-form {
	display: flex;
	align-items: center;

	&__info {
		max-width: 40rem;
		margin-right: auto;
	}

	&__title {
		font-size: 3.6rem;
		font-weight: 700;
	}

	&__form {
		max-width: 37rem;

		.input-field {
			margin-bottom: 2.5rem;
		}
	}

	&__button {
		margin-bottom: 2rem;
	}

	&__legend {
		font-size: 1.2rem;
	}
}

@media (max-width: $tablet-width) {

	.contact-form {

		&__info {
			max-width: 25rem;
		}

		&__form {
			max-width: 30rem;

			.input-field {
				margin-bottom: 1.5rem;
			}
		}

		&__button {
			margin-bottom: 1rem;
		}

		&__legend {
			font-size: 1rem;
		}

	}
}

@media (max-width: $mobile-width) {

	.contact-form {
		display: block;

		&__info,
		&__form {
			max-width: 100%;
		}
	}
}