.page-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);

	&__burger {
		display: none;
	}
}

.page-header__wrapper {
	display: flex;
	align-items: center;
}

.page-header__top {
	position: relative;
	z-index: 10;
	padding: 1rem 0;

	color: $white-text;
	
	background-color: $base-color;
	background-image: url('../img/background/bg.jpg');
	background-size: cover;
}

.page-header__lang {
	margin-right: 1.5rem;

	font-size: 1.6rem;
	line-height: 1.2;
	transition: all 0.2s ease-out;

	&:hover,
	&:focus {
		color: $white-hover;
	}
}

.page-header__search {
	margin-right: auto;
}

.page-header__contacts {
	display: flex;
	align-items: center;
	margin-left: 2rem;
}

.page-header__bottom {
	background-color: #fff;
}

.page-header__logo {
	width: 101px;
	height: 43px;
	margin-right: 3rem;
}

.page-header__nav {
	margin-right: auto; 
}

.page-header__button-box {
	display: flex;
	align-items: center;

	.button:not(:first-child) {
		margin-left: 1.5rem;
	}
}

@media (max-width: $tablet-width) {

	.page-header {

		&__logo {
			width: 90px;
			height: auto;
			margin-right: 1.5rem;
		}

		&__contact-button {
			display: none;
		}
	}
}

@media (max-width: $mobile-width) {

	.page-header {

		&__contacts {
			margin-left: auto;
		}

		&__phone {

			span {
				display: none;
			}
		}

		&__search {
			display: none;
		}

		&__nav {
			display: none;
		}

		&__bottom {
			display: flex;
			align-items: center;
			height: 56px;
		}

		&__button-box {
			margin-left: auto;
		}

		&__burger {
			position: relative;
			z-index: 10;
			display: flex;
			margin-left: 1rem;

			.is-open {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 0;
				height: 0;
				transform: translate(-50%, -50%);
				background-color: $white-text;
				opacity: 0;

				transition: all 0.2s ease-out;
			}

			&.is-active {

				.is-open {
					width: 32px;
					height: 32px;
					padding: 7px;
					opacity: 1;

					transition: all 0.2s ease-out;
				}
			}
		}
	}
}