html {
	font-size: 10px;
}

body {
	position: relative;
	min-width: 320px;

	font-family: $base-font;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.4;
	font-style: normal;
	color: $base-text;
}

body.lock {
	overflow: hidden;
}

main {
	position: relative;
	min-height: calc(100vh - 120px);
	padding-top: 120px;
}

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	border: none;
	outline: none;
}

*::placeholder {
	color: rgba(57, 70, 79, 0.7);
}

img, 
svg {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

svg {
	fill: currentColor;
}

a {
	color: inherit;
	text-decoration: none;
}


ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

button {
	font: inherit;
	background-color: transparent;
	border: none;

	cursor: pointer;
}

.visually-hidden:not(:focus):not(:active) {
	position: absolute;

	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;

	white-space: nowrap;

	clip-path: inset(100%); 
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
}

.wrapper {
	position: relative;
	width: 100%;
	max-width: $tablet-width;
	padding: 0 30px;
	margin: 0 auto;

	&--row {
		display: flex;
		align-items: center;
	}
}


@media (min-width: 320px) and (max-width: $tablet-width) {

	html {
		font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1200 - 320)));
	}
}

@media (max-width: $tablet-width) {

	main {
		padding-top: 10rem;
	}
}

@media (max-width: $mobile-width) {

	.wrapper {
		padding: 0 15px;
	}
}