.burger-menu {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: none;
	flex-direction: column;
	width: 0;
	height: 0;
	background-color: #fff;
	padding-top: 42px;
	z-index: 0;
	opacity: 0;
	transition: all 0.25s ease-out;

	&.is-active {
		display: flex;
		width: 100vw;
		height: 100vh;
		opacity: 1;
		transition: all 0.25s ease-out;
	}

	&__header {
		height: 56px;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	}

	&__wrapper {
		height: 100%;
	}

	&__search {
		color: $base-color;
	}

	&__body {
		flex-grow: 1;
		overflow: auto;
		padding: 2rem 0;
	}

	&__button {
		margin-top: 3rem;
		width: 100%;
		font-size: 1.4rem;
		text-align: center;
	}
}