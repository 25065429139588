.phone {

	span {
		display: inline-block;
		font-size: 1.6rem;
		margin-right: 5px;
	}

	a {
		font-size: 2rem;

		transition: all 0.2s ease-out;

		color: $white-text;

		&:hover,
		&:focus {
			color: $white-hover;
			text-decoration: underline;
		}
	}
}

@media (max-width: $tablet-width) {

	.phone {

		a {
			font-size: 1.6rem;
		}
	}
}