.product-section {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -3rem;

	&__item {
		width: calc(50% - 3rem/2);
		margin-right: 3rem;
		margin-bottom: 3rem;

		border-radius: 10px;
	}

	&__link {
		display: flex;
		position: relative;

		border-radius: inherit;
		background-color: #fff;

		transition: all 0.25s ease-out;

		&:hover,
		&:focus {
			box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
		}
	}

	&__image {
		width: 100%;
		height: 100%;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__name {
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);
		max-width: 50%;
		padding: 0 2rem;

		font-size: 3.2rem;
		font-weight: 500;
		text-transform: uppercase;
		border-left: 3px solid;
	}
}

.product-section__item {

	&:nth-child(odd) {

		.product-section__name {
			left: 50%;
		}
	}

	&:nth-child(even) {
		margin-right: 0;

		.product-section__image {
			left: auto;
			right: 0;
		}
	}
}

@media (max-width: $tablet-width) {

	.product-section {
		margin-bottom: -1rem;

		&__item {
			width: calc(50% - 1rem/2);
			margin-right: 1rem;
			margin-bottom: 1rem;
		}
		

		&__name {
			padding: 1.5rem;
			font-size: 1.6rem;
		}
	}
}

@media (max-width: $mobile-width) {

	.product-section {
		margin-bottom: -1rem;

		&__item {
			width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
		}
	}
}