.footer-contacts {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&__link-map {
		margin-bottom: 2.5rem;
	}

	&__title {
		font-size: 1.6rem;
		font-weight: 500;
		margin-bottom: 2rem;
	}

	&__address {
		margin-bottom: 3.6rem;
		font-style: normal;
		text-align: right;
	}

	&__phone {
		margin-bottom: 5px;
	}
}

@media (max-width: $tablet-width) {

	.footer-contacts {
		
		&__link-map {
			margin-bottom: 1.5rem;
		}

		&__title {
			font-size: 1.4rem;
			margin-bottom: 7px;
		}

		&__address {
			margin-bottom: 2rem;
			font-size: 1.2rem;
		}
	}
}