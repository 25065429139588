.button {
	display: inline-block; 
	padding: 1rem 1.5rem;

	font-size: 1.6rem;
	font-weight: 500;
	color: $white-text;

	border-radius: 8px;
	background-color: $button-color;

	cursor: pointer;
	transition: all 0.2s ease-out;

	&:hover,
	&:focus {
		color: $white-text;
		background-color: $button-color-hover;
		border-color: $button-color-hover;
	}

	&--transparent {
		color: $button-color;

		border: 1.5px solid $button-color;
		background-color: transparent;
	}

	&--green {
		background-color: #43D854;

		&:hover,
		&:focus {
			opacity: 0.7;
			background-color: #43D854;
		}
	}

	&--blue {
		background-color: #0088CC;

		&:hover,
		&:focus {
			opacity: 0.7;
			background-color: #0088CC;
		}
	}

	&--transparent-white {
		color: $white-text;
		background-color: transparent;
		border: 1.5px solid $white-text;

		&:hover,
		&:focus {
			color: $base-color;
			background-color: $white-text;
			border-color: $white-text;
		}
	}

	&--large {
		width: 100%;
		padding: 1.5rem;
		font-size: 1.8rem;
	}

	&--flex {
		display: flex;
		align-items: center;
		width: calc(50% - 8px/2);
		padding: 1rem;

		&__icon {
			width: 24px;
			height: 24px;
			margin-right: 5px;
		}

		&__text {
			font-size: 1.2rem;
		}
	}

	&--icon {
		padding: 1rem;

		&--transparent {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			color: $base-color;
			background-color: transparent;
		}
	}
}

@media (max-width: $tablet-width) {

	.button {
		padding: 1rem 1.5rem;

		font-size: 1.4rem;
	}
}

@media (max-width: $mobile-width) {

	.button {

		&--icon,
		&--flex {
			padding: 1rem;
		}
	}
}