/* roboto-regular - latin_cyrillic */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto/roboto-v20-latin_cyrillic-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-v20-latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/roboto/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/roboto/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"), url("../fonts/roboto/roboto-v20-latin_cyrillic-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-500 - latin_cyrillic */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../fonts/roboto/roboto-v20-latin_cyrillic-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-v20-latin_cyrillic-500.woff2") format("woff2"), url("../fonts/roboto/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/roboto/roboto-v20-latin_cyrillic-500.ttf") format("truetype"), url("../fonts/roboto/roboto-v20-latin_cyrillic-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-700 - latin_cyrillic */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/roboto/roboto-v20-latin_cyrillic-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-v20-latin_cyrillic-700.woff2") format("woff2"), url("../fonts/roboto/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/roboto/roboto-v20-latin_cyrillic-700.ttf") format("truetype"), url("../fonts/roboto/roboto-v20-latin_cyrillic-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}

html {
  font-size: 10px;
}

body {
  font: normal 400 1.6rem/1.4 "Roboto", "Arial", sans-serif;
  position: relative;
  min-width: 320px;
  color: #333333;
}

body.lock {
  overflow: hidden;
}

main {
  position: relative;
  min-height: calc(100vh - 120px);
  padding-top: 120px;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
}

*::-moz-placeholder {
  color: rgba(57, 70, 79, 0.7);
}

*:-ms-input-placeholder {
  color: rgba(57, 70, 79, 0.7);
}

*::placeholder {
  color: rgba(57, 70, 79, 0.7);
}

img,
svg {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

svg {
  fill: currentColor;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  background: transparent;
  font: inherit;
  border: none;
  cursor: pointer;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
}

.wrapper--row {
  display: flex;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 1200px) {
  html {
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1200 - 320)));
  }
}

@media (max-width: 1200px) {
  main {
    padding-top: 10rem;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 0 15px;
  }
}

.logo {
  transition: all 0.2s ease-out;
}

.logo:hover,
.logo:focus {
  opacity: 0.7;
}

.main-nav {
  display: flex;
}

.main-nav__item:not(:last-child) {
  margin-right: 2rem;
}

.main-nav__link {
  position: relative;
  display: block;
  padding: 2rem 0;
  font-size: 1.8rem;
  font-weight: 500;
  color: #32498A;
}

.main-nav__link::before {
  background: #32498A;
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  width: 0;
  height: 4px;
  transform: translateX(-50%);
  transition: all 0.2s ease-out;
}

.main-nav__link.is-active::before,
.main-nav__link:hover::before {
  width: 100%;
}

@media (max-width: 1200px) {
  .main-nav__item:not(:last-child) {
    margin-right: 1rem;
  }

  .main-nav__link {
    padding: 1.5rem 0;
    font-size: 1.6rem;
  }
}

.input-field {
  width: 100%;
  height: 30px;
  padding: 5px 0;
  border-bottom: 1px solid #333333;
  font-size: 1.6rem;
}

@media (max-width: 1200px) {
  .input-field {
    font-size: 1.2rem;
  }
}

.button {
  background: #FF5C00;
  display: inline-block;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.button:hover,
.button:focus {
  background: rgba(255, 92, 0, 0.8);
  color: #FFFFFF;
  border-color: rgba(255, 92, 0, 0.8);
}

.button--transparent {
  background: transparent;
  color: #FF5C00;
  border: 1.5px solid #FF5C00;
}

.button--green {
  background: #43D854;
}

.button--green:hover,
.button--green:focus {
  background: #43D854;
  opacity: 0.7;
}

.button--blue {
  background: #0088CC;
}

.button--blue:hover,
.button--blue:focus {
  background: #0088CC;
  opacity: 0.7;
}

.button--transparent-white {
  background: transparent;
  color: #FFFFFF;
  border: 1.5px solid #FFFFFF;
}

.button--transparent-white:hover,
.button--transparent-white:focus {
  background: #FFFFFF;
  color: #32498A;
  border-color: #FFFFFF;
}

.button--large {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.8rem;
}

.button--flex {
  display: flex;
  align-items: center;
  width: calc(50% - 8px/2);
  padding: 1rem;
}

.button--flex__icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.button--flex__text {
  font-size: 1.2rem;
}

.button--icon {
  padding: 1rem;
}

.button--icon--transparent {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: #32498A;
}

@media (max-width: 1200px) {
  .button {
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .button--icon,
  .button--flex {
    padding: 1rem;
  }
}

.product-section {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -3rem;
}

.product-section__item {
  width: calc(50% - 3rem/2);
  margin-right: 3rem;
  margin-bottom: 3rem;
  border-radius: 10px;
}

.product-section__link {
  background: #fff;
  display: flex;
  position: relative;
  border-radius: inherit;
  transition: all 0.25s ease-out;
}

.product-section__link:hover,
.product-section__link:focus {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.product-section__image {
  width: 100%;
  height: 100%;
}

.product-section__image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-section__name {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  max-width: 50%;
  padding: 0 2rem;
  font-size: 3.2rem;
  font-weight: 500;
  text-transform: uppercase;
  border-left: 3px solid;
}

.product-section__item:nth-child(odd) .product-section__name {
  left: 50%;
}

.product-section__item:nth-child(even) {
  margin-right: 0;
}

.product-section__item:nth-child(even) .product-section__image {
  left: auto;
  right: 0;
}

@media (max-width: 1200px) {
  .product-section {
    margin-bottom: -1rem;
  }

  .product-section__item {
    width: calc(50% - 1rem/2);
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .product-section__name {
    padding: 1.5rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .product-section {
    margin-bottom: -1rem;
  }

  .product-section__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.button-contacts {
  display: none;
  margin-top: 2rem;
  margin-bottom: -2rem;
}

@media (max-width: 767px) {
  .button-contacts {
    display: flex;
    justify-content: space-between;
  }
}

.flex-row {
  display: flex;
}

.flex-row--align--baseline {
  align-items: baseline;
}

.phone span {
  display: inline-block;
  font-size: 1.6rem;
  margin-right: 5px;
}

.phone a {
  font-size: 2rem;
  transition: all 0.2s ease-out;
  color: #FFFFFF;
}

.phone a:hover,
.phone a:focus {
  color: #B5B5B5;
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .phone a {
    font-size: 1.6rem;
  }
}

.link {
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.link:hover,
.link:focus {
  color: #B5B5B5;
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  color: #FFFFFF;
  transition: all 0.25s ease-out;
}

.search__icon {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 32px;
  height: 100%;
  margin-right: 5px;
  padding: 5px 15px;
  color: inherit;
}

.search__icon:hover,
.search__icon:focus {
  color: #B5B5B5;
}

.search__icon svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-out;
}

.search__icon span {
  padding-left: 35px;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.2s ease-out;
}

.search__close {
  display: none;
  position: absolute;
  right: 0;
  z-index: 2;
}

.search__field {
  background: transparent;
  width: 0;
  padding: 5px 15px;
  padding-right: 32px;
  font-size: 1.6rem;
  color: #32498A;
  transition: all 0.25s ease-out;
}

.search.is-active {
  background: #FFFFFF;
  width: 100%;
  max-width: 57rem;
  border-radius: 4px;
}

.search.is-active .search__icon {
  color: #32498A;
}

.search.is-active .search__icon span {
  display: none;
}

.search.is-active .search__field {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.search.is-active .search__close {
  display: flex;
}

@media (max-width: 1200px) {
  .search__icon {
    padding: 5px 10px;
  }

  .search__icon svg {
    left: 10px;
    width: 18px;
    height: 18px;
  }

  .search__field {
    padding: 5px 10px;
    padding-right: 32px;
  }

  .search__close svg {
    width: 12px;
    height: 12px;
  }

  .search.is-active {
    max-width: 24rem;
  }
}

@media (max-width: 767px) {
  .search.is-active {
    max-width: 100%;
  }
}

.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.page-header__burger {
  display: none;
}

.page-header__wrapper {
  display: flex;
  align-items: center;
}

.page-header__top {
  background: url("../img/background/bg.jpg") 0 0/cover #32498A;
  position: relative;
  z-index: 10;
  padding: 1rem 0;
  color: #FFFFFF;
}

.page-header__lang {
  margin-right: 1.5rem;
  font-size: 1.6rem;
  line-height: 1.2;
  transition: all 0.2s ease-out;
}

.page-header__lang:hover,
.page-header__lang:focus {
  color: #B5B5B5;
}

.page-header__search {
  margin-right: auto;
}

.page-header__contacts {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.page-header__bottom {
  background: #fff;
}

.page-header__logo {
  width: 101px;
  height: 43px;
  margin-right: 3rem;
}

.page-header__nav {
  margin-right: auto;
}

.page-header__button-box {
  display: flex;
  align-items: center;
}

.page-header__button-box .button:not(:first-child) {
  margin-left: 1.5rem;
}

@media (max-width: 1200px) {
  .page-header__logo {
    width: 90px;
    height: auto;
    margin-right: 1.5rem;
  }

  .page-header__contact-button {
    display: none;
  }
}

@media (max-width: 767px) {
  .page-header__contacts {
    margin-left: auto;
  }

  .page-header__phone span {
    display: none;
  }

  .page-header__search {
    display: none;
  }

  .page-header__nav {
    display: none;
  }

  .page-header__bottom {
    display: flex;
    align-items: center;
    height: 56px;
  }

  .page-header__button-box {
    margin-left: auto;
  }

  .page-header__burger {
    position: relative;
    z-index: 10;
    display: flex;
    margin-left: 1rem;
  }

  .page-header__burger .is-open {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  .page-header__burger.is-active .is-open {
    width: 32px;
    height: 32px;
    padding: 7px;
    opacity: 1;
    transition: all 0.2s ease-out;
  }
}

.page-footer {
  background: url("../img/background/bg.jpg") 0 0/cover #32498A;
  padding: 5rem 0 3rem;
  color: #FFFFFF;
}

.page-footer__wrapper {
  display: flex;
}

.page-footer__social {
  margin-bottom: 3rem;
}

.page-footer__info {
  max-width: 350px;
  margin: 0 auto;
}

.page-footer__logo a {
  display: block;
  margin: 0 auto;
}

.page-footer__copy {
  font-size: 1.2rem;
  text-align: center;
}

.page-footer__contacts {
  max-width: 30rem;
}

@media (max-width: 1200px) {
  .page-footer__social {
    margin-bottom: 1.5rem;
  }

  .page-footer__contacts {
    max-width: 25rem;
  }
}

@media (max-width: 767px) {
  .page-footer {
    padding: 2rem 0;
  }

  .page-footer__wrapper {
    flex-direction: column;
  }

  .page-footer__contacts {
    order: -1;
    align-self: flex-end;
    max-width: 25rem;
    margin-bottom: 2rem;
  }

  .page-footer__site-nav {
    display: none;
  }

  .page-footer__social {
    max-width: 360px;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .page-footer__social .social-list {
    justify-content: space-between;
  }

  .page-footer__logo .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
  }
}

.banner {
  padding: 3.2rem 0;
  border-radius: 20px;
  color: #FFFFFF;
  overflow: hidden;
}

.banner__list {
  display: flex;
  flex-wrap: wrap;
}

.banner__item {
  position: relative;
  width: 100%;
  height: auto;
}

.banner__info {
  position: absolute;
  top: 50%;
  left: 10rem;
  transform: translateY(-50%);
}

.banner__info--mini {
  max-width: 40%;
}

.banner__info--middle {
  max-width: 50%;
}

.banner__info--top {
  top: 5rem;
  transform: translateY(0);
}

.banner__info--column {
  display: flex;
  flex-wrap: wrap;
}

.banner__info--column .banner__text:not(:last-child) {
  padding-right: 9rem;
}

.banner__text {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.3;
}

.banner__text--small {
  font-size: 3.2rem;
}

.banner__text--small span {
  font-size: 7.2rem;
}

.banner__text span {
  display: block;
}

.banner__link {
  display: inline-block;
  margin-top: 1rem;
  font-size: 2.8rem;
  color: #083E6B;
}

@media (max-width: 1200px) {
  .banner {
    padding: 1.5rem 0;
  }

  .banner__info {
    left: 2rem;
  }

  .banner__info--mini,
  .banner__info--middle {
    max-width: 100%;
  }

  .banner__info--top {
    top: 50%;
    transform: translateY(-50%);
  }

  .banner__info--column {
    flex-wrap: wrap;
  }

  .banner__info--column .banner__text:not(:last-child) {
    padding-right: 2rem;
  }

  .banner__text {
    font-size: 2.6rem;
  }

  .banner__text--small {
    font-size: 1.8rem;
  }

  .banner__text--small span {
    font-size: 3.2rem;
  }

  .banner__link {
    font-size: 2rem;
  }
}

@media (max-width: 1200px) {
  .banner__image {
    height: 24rem;
  }

  .banner__image img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 20px;
  }
}

.info-nav__list {
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.info-nav__item {
  flex-grow: 1;
  border-right: 1px solid #e9e9e9;
}

.info-nav__item:last-child {
  border-right: none;
}

.info-nav__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 3rem 2rem;
  transition: all 0.25s ease-out;
}

.info-nav__link:hover,
.info-nav__link:focus {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.info-nav__link:hover::after,
.info-nav__link:focus::after {
  right: 10px;
}

.info-nav__link::after {
  content: url("../img/icons/arrow-right-black.svg");
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  transition: all 0.4s ease-out;
}

.info-nav__icon {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-right: 1.8rem;
}

.info-nav__name {
  font-size: 1.8rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .info-nav__list {
    flex-wrap: wrap;
  }

  .info-nav__item {
    width: 50%;
    border-bottom: 1px solid #e9e9e9;
  }

  .info-nav__link {
    padding: 1rem 1.5rem;
  }

  .info-nav__icon {
    margin-right: 1.2rem;
  }

  .info-nav__name {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .info-nav__item {
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
  }
}

.home-section {
  padding: 6rem 0;
}

.home-section--theme-gray {
  background: #E9E9E9;
}

.home-section--theme-blue {
  background: #8ECADE;
}

.home-section__header {
  margin-bottom: 3.5rem;
}

.home-section__title {
  margin-bottom: 1rem;
  font-size: 3.6rem;
  font-weight: 700;
  color: #32498A;
}

.home-section__link {
  position: relative;
  display: inline-block;
  line-height: 1;
  transition: all 0.25s ease-out;
  border-bottom: 3px solid transparent;
}

.home-section__link:hover,
.home-section__link:focus {
  opacity: 0.7;
  border-bottom: 3px solid #32498A;
}

.home-section__link:hover::after,
.home-section__link:focus::after {
  right: -30px;
}

.home-section__link::after {
  content: url("../img/icons/arrow-right-blue.svg");
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  width: 12px;
  height: auto;
  transition: all 0.4s ease-out;
}

.home-section__name {
  font-size: 2rem;
}

.home-section__content {
  position: relative;
  display: flex;
  width: 100%;
  height: 44rem;
  border-radius: 20px;
}

.home-section__content--center {
  justify-content: center;
  align-items: center;
}

.home-section__img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.home-section__img--paralax img {
  -o-object-fit: none;
  object-fit: none;
}

.home-section__img img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.home-section__text {
  position: relative;
  max-width: 60rem;
  font-size: 4rem;
  font-weight: 700;
  color: #FFFFFF;
}

.home-section__body--white {
  background: #fff;
  padding: 7rem 10rem;
}

@media (max-width: 1200px) {
  .home-section {
    padding: 2.5rem 0;
  }

  .home-section__header {
    margin-bottom: 2rem;
  }

  .home-section__title {
    font-size: 2.4rem;
  }

  .home-section__link::after {
    width: 8px;
  }

  .home-section__description {
    font-size: 1.4rem;
  }

  .home-section__body--white {
    padding: 2rem 4rem;
  }

  .home-section__content {
    height: 24rem;
  }

  .home-section__content--center {
    padding: 20px 32%;
  }

  .home-section__img--paralax img {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .home-section__text {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .home-section--mobile-none {
    display: none;
  }

  .home-section__body--white {
    padding: 2.5rem 2rem;
  }
}

.news-list {
  display: flex;
  margin: 0 -1.5rem;
  margin-top: -3rem;
}

.news-list__item {
  margin: 0 1.5rem;
  margin-top: 3rem;
}

.news-list__image {
  position: relative;
  display: block;
  width: auto;
  height: auto;
  margin-bottom: 2.5rem;
  border-radius: 20px;
  transition: all 0.25s ease-out;
}

.news-list__image:hover::before,
.news-list__image:focus::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.news-list__image img {
  border-radius: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.news-list__title {
  display: inline-block;
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: 500;
  color: #32498A;
  transition: all 0.25s ease-out;
}

.news-list__title:hover,
.news-list__title:focus {
  opacity: 0.7;
  text-decoration: underline;
}

.news-list__description {
  font-size: 1.8rem;
}

@media (max-width: 1200px) {
  .news-list__image {
    margin-bottom: 1.5rem;
  }

  .news-list__title {
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }

  .news-list__description {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .news-list__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .news-list__description {
    display: none;
  }
}

.contact-form {
  display: flex;
  align-items: center;
}

.contact-form__info {
  max-width: 40rem;
  margin-right: auto;
}

.contact-form__title {
  font-size: 3.6rem;
  font-weight: 700;
}

.contact-form__form {
  max-width: 37rem;
}

.contact-form__form .input-field {
  margin-bottom: 2.5rem;
}

.contact-form__button {
  margin-bottom: 2rem;
}

.contact-form__legend {
  font-size: 1.2rem;
}

@media (max-width: 1200px) {
  .contact-form__info {
    max-width: 25rem;
  }

  .contact-form__form {
    max-width: 30rem;
  }

  .contact-form__form .input-field {
    margin-bottom: 1.5rem;
  }

  .contact-form__button {
    margin-bottom: 1rem;
  }

  .contact-form__legend {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .contact-form {
    display: block;
  }

  .contact-form__info,
  .contact-form__form {
    max-width: 100%;
  }
}

.partners-list {
  display: flex;
}

.partners-list__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 8);
}

.partners-list__item img {
  width: 90%;
  height: 90%;
}

.social-list {
  display: flex;
}

.social-list__item {
  width: 32px;
  height: 32px;
  margin-right: 1.5rem;
}

.social-list__item:last-child {
  margin-right: 0;
}

.social-list__link {
  display: block;
  color: #FFFFFF;
  transition: all 0.2s ease-out;
}

.social-list__link:hover {
  color: #B5B5B5;
}

.social-list--small .social-list__item {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

@media (max-width: 1200px) {
  .social-list__item {
    margin-right: 1rem;
  }
}

.footer-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-contacts__link-map {
  margin-bottom: 2.5rem;
}

.footer-contacts__title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.footer-contacts__address {
  margin-bottom: 3.6rem;
  font-style: normal;
  text-align: right;
}

.footer-contacts__phone {
  margin-bottom: 5px;
}

@media (max-width: 1200px) {
  .footer-contacts__link-map {
    margin-bottom: 1.5rem;
  }

  .footer-contacts__title {
    font-size: 1.4rem;
    margin-bottom: 7px;
  }

  .footer-contacts__address {
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }
}

.site-nav--blue {
  color: #32498A;
}

.site-nav__item {
  margin-bottom: 1rem;
}

.site-nav__item:last-child {
  margin-bottom: 0;
}

.site-nav__link {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  transition: all 0.25s ease-out;
}

.site-nav__link:hover,
.site-nav__link:focus {
  color: #B5B5B5;
}

@media (max-width: 1200px) {
  .site-nav__item {
    margin-bottom: 0.5rem;
  }

  .site-nav__link {
    font-size: 1.4rem;
  }
}

@media (max-width: 1200px) {
  .site-nav__item {
    margin-bottom: 1rem;
  }

  .site-nav__link {
    font-size: 1.6rem;
  }
}

.burger-menu {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  flex-direction: column;
  width: 0;
  height: 0;
  padding-top: 42px;
  z-index: 0;
  opacity: 0;
  transition: all 0.25s ease-out;
}

.burger-menu.is-active {
  display: flex;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: all 0.25s ease-out;
}

.burger-menu__header {
  height: 56px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.burger-menu__wrapper {
  height: 100%;
}

.burger-menu__search {
  color: #32498A;
}

.burger-menu__body {
  flex-grow: 1;
  overflow: auto;
  padding: 2rem 0;
}

.burger-menu__button {
  margin-top: 3rem;
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
}