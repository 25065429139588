// Цвета
$base-color: #32498A;
$secondary-color: #3B73B8;
$base-text: #333333;
$white-text: #FFFFFF;
$white-hover: #B5B5B5;
$base-bg: #E9E9E9;
$secondary-bg: #8ECADE;
$button-color: #FF5C00;
$button-color-hover: rgba(255, 92, 0, 0.8);

// Шрифты
$base-font: 'Roboto', 'Arial', sans-serif;

// Вьюпорт
$mobile-width: 767px;
$tablet-width: 1200px;
$retina-dppx: 1.5dppx;
$retina-dpi: 144dpi;