.site-nav {

	&--blue {
		color: $base-color;
	}

	&__item {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		display: inline-block;
		font-size: 1.8rem;
		font-weight: 500;

		transition: all 0.25s ease-out;

		&:hover,
		&:focus {
			color: $white-hover;
		}
	}
}

@media (max-width: $tablet-width) {

	.site-nav {

		&__item {
			margin-bottom: 0.5rem;
		}

		&__link {
			font-size: 1.4rem;
		}
	}
}

@media (max-width: $tablet-width) {

	.site-nav {

		&__item {
			margin-bottom: 1rem;
		}

		&__link {
			font-size: 1.6rem;
		}
	}
}