.page-footer {
	padding: 5rem 0 3rem;
	color: $white-text;

	background-color: $base-color;
	background-image: url('../img/background/bg.jpg');
	background-size: cover;

	&__wrapper {
		display: flex;
	}

	&__social {
		margin-bottom: 3rem;
	}

	&__info {
		max-width: 350px;
		margin: 0 auto;
	}

	&__logo a {
		display: block;
		margin: 0 auto;
	}

	&__copy {
		font-size: 1.2rem;
		text-align: center;
	}

	&__contacts {
		max-width: 30rem;
	}
}

@media (max-width: $tablet-width) {

	.page-footer {

		&__social {
			margin-bottom: 1.5rem;
		}

		&__contacts {
			max-width: 25rem;
		}
	}
}

@media (max-width: $mobile-width) {

	.page-footer {
		padding: 2rem 0;

		&__wrapper {
			flex-direction: column;
		}
		
		&__contacts {
			order: -1;
			align-self: flex-end;
			max-width: 25rem;
			margin-bottom: 2rem;
		}

		&__site-nav {
			display: none;
		}

		&__social {
			max-width: 360px;
			margin: 0 auto;
			margin-bottom: 1.5rem;

			.social-list {
				justify-content: space-between;
			}
		}

		&__logo {

			.logo {
				position: absolute;
				top: 0;
				left: 0;
				width: 10rem;
			}
		}
	}
}