.banner {
	padding: 3.2rem 0;
	border-radius: 20px;
	color: $white-text;
	overflow: hidden;

	&__list {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		position: relative;
		width: 100%;
		height: auto;
	}

	&__info {
		position: absolute;
		top: 50%;
		left: 10rem;
		transform: translateY(-50%);

		&--mini {
			max-width: 40%;
		}

		&--middle {
			max-width: 50%;
		}

		&--top {
			top: 5rem;
			transform: translateY(0);
		}

		&--column {
			display: flex;
			flex-wrap: wrap;

			.banner__text {

				&:not(:last-child) {
					padding-right: 9rem;
				}
			}
		}
	}

	&__text {
		font-size: 4.8rem;
		font-weight: 700;
		line-height: 1.3;

		&--small {
			font-size: 3.2rem;

			span {
				font-size: 7.2rem;
			}
		}

		span {
			display: block;
		}
	}

	&__link {
		display: inline-block;
		margin-top: 1rem;
		
		font-size: 2.8rem;
		color: #083E6B;
	}
}

@media (max-width: $tablet-width) {

	.banner {
		padding: 1.5rem 0;

		&__info {
			left: 2rem;

			&--mini,
			&--middle {
				max-width: 100%;
			}

			&--top {
				top: 50%;
				transform: translateY(-50%);
			}

			&--column {
				flex-wrap: wrap;

				.banner__text {

					&:not(:last-child) {
						padding-right: 2rem;
					}
				}
			}
		}

		&__text {
			font-size: 2.6rem;

			&--small {
				font-size: 1.8rem;

				span {
					font-size: 3.2rem;
				}
			}
		}

		&__link {
			font-size: 2rem;
		}
	}
}

@media (max-width: $tablet-width) {

	.banner {
		
		&__image {
			height: 24rem;

			img {
				height: 100%;
				object-fit: cover;
				border-radius: 20px;
			}
		}
	}
}