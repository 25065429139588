.info-nav {

	&__list {
		display: flex;
		border-radius: 10px;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	}

	&__item {
		flex-grow: 1;
		border-right: 1px solid #e9e9e9;

		&:last-child {
			border-right: none;
		}
	}

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		padding: 3rem 2rem;

		transition: all 0.25s ease-out;

		&:hover,
		&:focus {
			box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);

			&::after {
				right: 10px;
			}
		}

		&::after {
			content: url('../img/icons/arrow-right-black.svg');
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			width: 10px;
			height: 20px;
			transition: all 0.4s ease-out;
		}
	}

	&__icon {
		width: 36px;
		height: 36px;
		flex-shrink: 0;
		margin-right: 1.8rem;
	}

	&__name {
		font-size: 1.8rem;
		font-weight: 500;
	}
}

@media (max-width: $tablet-width) {

	.info-nav {

		&__list {
			flex-wrap: wrap;
		}

		&__item {
			width: 50%;
			border-bottom: 1px solid #e9e9e9;
		}

		&__link {
			padding: 1rem 1.5rem;
		}

		&__icon {
			margin-right: 1.2rem;
		}

		&__name {
			font-size: 1.6rem;
		}
	}
}

@media (max-width: $mobile-width) {

	.info-nav {

		&__item {
			width: 100%;
			border-bottom: 1px solid #e9e9e9;
		}
	}
}