.home-section {
	padding: 6rem 0;

	&--theme-gray {
		background-color: $base-bg;
	}

	&--theme-blue {
		background-color: $secondary-bg;
	}

	&__header {
		margin-bottom: 3.5rem;
	}

	&__title {
		margin-bottom: 1rem;
		
		font-size: 3.6rem;
		font-weight: 700;
		color: $base-color;
	}

	&__link {
		position: relative;
		display: inline-block;
		line-height: 1;

		transition: all 0.25s ease-out;
		border-bottom: 3px solid transparent;

		&:hover,
		&:focus {
			opacity: 0.7;
			border-bottom: 3px solid $base-color;

			&::after {
				right: -30px;
			}
		}

		&::after {
			content: url('../img/icons/arrow-right-blue.svg');
			position: absolute;
			top: 50%;
			right: -20px;
			transform: translateY(-50%);
			width: 12px;
			height: auto;
			transition: all 0.4s ease-out;
		}
	}

	&__name {
		font-size: 2rem;
	}

	&__content {
		position: relative;
		display: flex;
		width: 100%;
		height: 44rem;
		border-radius: 20px;

		&--center {
			justify-content: center;
			align-items: center;
		}
	}

	&__img {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: inherit;

		&--paralax {

			img {
				object-fit: none;
			}
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: inherit;
		}
	}

	&__text {
		position: relative;
		max-width: 60rem;
		font-size: 4rem;
		font-weight: 700;
		color: $white-text;
	}

	&__body--white {
		padding: 7rem 10rem;
		background-color: #fff;
	}
}

@media (max-width: $tablet-width) {

	.home-section {
		padding: 2.5rem 0;

		&__header {
			margin-bottom: 2rem;
		}

		&__title {
			font-size: 2.4rem;
		}

		&__link {

			&::after {
				width: 8px;
			}
		}

		&__description {
			font-size: 1.4rem;
		}

		&__body--white {
			padding: 2rem 4rem;
		}

		&__content {
			height: 24rem;

			&--center {
				padding: 20px 32%;
			}
		}

		&__img {

			&--paralax img {
				object-fit: cover;
			}
		}

		&__text {
			font-size: 2rem;
		}
	}
}

@media (max-width: $mobile-width) {

	.home-section {

		&--mobile-none {
			display: none;
		}

		&__body--white {
			padding: 2.5rem 2rem;
		}
	}
}