.search {
	position: relative;
	display: flex;
	align-items: center;
	height: 32px;

	color: $white-text;
	transition: all 0.25s ease-out;
}

.search__icon {
	position: relative;
	display: flex;
	align-items: center;
	min-width: 32px;
	height: 100%;
	margin-right: 5px;
	padding: 5px 15px;
	color: inherit;

	&:hover,
	&:focus {
		color: $white-hover;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 15px;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		transition: all 0.2s ease-out;
	}

	span {
		padding-left: 35px;
		font-size: 1.6rem;
		font-weight: 500;
		transition: all 0.2s ease-out;
	}
}

.search__close {
	display: none;
	position: absolute;
	right: 0;
	z-index: 2;
}

.search__field {
	width: 0;
	padding: 5px 15px;
	padding-right: 32px;

	font-size: 1.6rem;
	color: $base-color;

	background-color: transparent;
	transition: all 0.25s ease-out;
}

.search.is-active {
	width: 100%;
	max-width: 57rem;
	background-color: $white-text;
	border-radius: 4px;

	.search__icon {
		color: $base-color;

		span {
			display: none;
		}
	}

	.search__field {
		position: relative;
		z-index: 10;
		width: 100%;
		height: 100%;
	}

	.search__close {
		display: flex;
	}
}

@media (max-width: $tablet-width) {

	.search__icon {
		padding: 5px 10px;

		svg {
			left: 10px;
			width: 18px;
			height: 18px;
		}
	}

	.search__field {
		padding: 5px 10px;
		padding-right: 32px;
	}

	.search__close {

		svg {
			width: 12px;
			height: 12px;
		}
	}

	.search.is-active {
		max-width: 24rem;
	}
}

@media (max-width: $mobile-width) {

	.search.is-active {
		max-width: 100%;
	}
}